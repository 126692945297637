body {
  background-color: black;
  color: ivory;
  margin: 0;
  padding:0;
}
.App {
    text-align: center;
}
.scrolling-text {
  position: absolute;
  top: 0;
  left: 0;
  height: 92vh;
  overflow: hidden;
  white-space: pre-wrap;
  padding: 50px;
  text-align: left;
  line-height:1.5em;
}

.highlight {
  background-color: burgundy;
  border-radius:5px;
}

.spacecraft {
  position: fixed;
  font-size: 3rem;
  background-color: hsla(0,0%,0%,0.5);
  border-radius: 5px;
}
.bullet, .bullet-shell {
  position: fixed;
  font-size:2rem;
  color: white;
}
.bullet-shell {
  animation: bullet-hit 0.5s forwards; 
  color: red;
}
.score, .lives {
  font-family:monospace;
  font-size:.8em;
  background-color: hsla(0,0%,0%,0.6);
  position: absolute;
}
.score {
  top: 10px;
  right: 10px;
}
.lives {
  top: 40px;
  right: 10px;
}

.App-header {
  position: relative;

    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}
.highlight {
    background-color: maroon;
    color: white;
    padding-left:.2em;
    padding-right:.2em;
    margin-right: .15em;
}
.hit, .collided {
  animation: fade 0.5s forwards; 
  color: white;
}

@keyframes fade {
  0% {background-color: red;}
  100% {background-color: #282c34;}
}
@keyframes bullet-hit {
  0% {color: red;}
  100% {opacity:0;}
}
.invert {
  filter: invert(1);
  transition: filter 0.5s;
}
.game-over.invert {
  filter: none;
}
.game-over .scrolling-text, 
.game-over .spacecraft,
.game-over .bullet {
  animation: bullet-hit 0.5s forwards;
}
.game-over-message {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.game-over-message h1 {
  font-size: 4em;
}
.game-over-message.visible {
  display: block;
  opacity: 1;
}

button.restart-button {
  font-size:1em;
  border: none;
  padding: .2em;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  border-radius: 5px;
  background-color: ivory;
}